import React from 'react';
import axios from "axios";
import Config from "../../Config";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from 'react-loading-skeleton';
import { NavLink, Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import PaginationComponent from '../../Components/Pagination';
import { InputGroup, FormControl, Card, Button, Col, Row, Table } from 'react-bootstrap'
var QRCode = require('qrcode.react');


export default function ListarEstudiantes() {

  const [dataEstudiantes, setDataEstudiantes] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [pageNumber, SetPageNumber] = React.useState(1);
  const [searchValue, SetSearchValue] = React.useState("");
  const [clearSearchValue, SetClearSearchValue] = React.useState(false);
  const [totalRecords, SetTotalRecords] = React.useState(0);
  const [paginationData, SetPaginationData] = React.useState(null);

  React.useEffect(() => {
    document.title = "myComply: Students";
    getEstudiantes();
  }, [pageNumber, clearSearchValue])


  const getEstudiantes = async () => {
    setLoading(true);
    await axios({
      method: 'get',
      url: `${Config.url}estudiantes`,
      params: {
        PageNumber: searchValue != '' ? 1 : pageNumber,
        SearchValue: searchValue,
      },
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    })
      // await axios.get(Config.url + 'estudiantes?PageNumber='+page)
      .then(response => {
        setLoading(false);
        console.log(response);
        setDataEstudiantes(response.data.data);
        SetPaginationData(response.data.filtro);
        SetTotalRecords(response.data.filtro.totalRecords);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      })
  }

  const paginate = async (page,search) => {
    SetPageNumber(page);
    SetSearchValue(search);
    //  getEstudiantes();
  };

  const handleSearch = (valueTosearch) => {
    //SetPageNumber(1);
    console.log(valueTosearch);
    SetSearchValue(valueTosearch);

  }

  const handleButtonSearch = () => getEstudiantes();
  
  const handleClearInput = () => {
    console.log("clear input");
    SetClearSearchValue(!clearSearchValue);
    SetSearchValue("");
    //getEstudiantes();
  };

  const ConfirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await axios.delete(Config.url + 'estudiantes/' + id, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
              .then(response => {
                console.log(response);
                toast.success('Record deleted successfully');
                // searchTodos();
              })
              .catch(error => {
                console.log(error);
              })
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };



  return (
    <>
      <ToastContainer />
      <Row className="mt-3">
        <Col xs={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Row>
                <Col xs={6} md={6} lg={6}>
                  All Students
                </Col>
                <Col style={{ textAlign: 'right' }} xs={6} md={6} lg={6}>
                  <NavLink className="btn btn-primary" to="/0f29ddd4be2fe3e504e308f0e9827a39/admin/students/new">New Student</NavLink>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>

              <div className="row">
                <div className="col-lg-12">
                  <InputGroup className="mb-3">
                    <Button variant="outline-secondary" id="button-addon2" disabled={loading || !searchValue} onClick={() => handleClearInput()}>
                      Clear Input
                    </Button>
                    <input type="text" className="form-control" onChange={(e) => { e.preventDefault(); handleSearch(e.target.value) }} value={searchValue} />
                    <Button variant="outline-secondary" id="button-addon2" disabled={loading || !searchValue} onClick={() => handleButtonSearch()}>
                      Search
                    </Button>
                  </InputGroup>

                </div>

              </div>
              {!loading
                ?
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <h5>Total Records: {totalRecords}</h5>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="table-responsive">
                      <Table>
                        <thead>

                          <tr>
                            <th>Photo</th>
                            <th>QR</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Card Number</th>
                            <th>Expire Date</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dataEstudiantes.map((data, index) => {
                              // let avance = (data.certificado.length * 100) / 12;
                              // console.log(avance);
                              return (

                                <tr key={index.toString()}>
                                  <td>
                                    {data.foto ?
                                      <div className="avatar avatar-xl">
                                        <img alt="avatar" src={data.foto} style={{ width: 80 }} className="rounded-circle" />
                                      </div>
                                      : <>No Foto</>
                                    }
                                  </td>
                                  <td>
                                    <a href={Config.urlqrcode + '?' + 'CAD=' + data.cardNumber + Config.urlqrcode2} target="_blank"> <QRCode value={Config.urlqrcode + '?' + 'CAD=' + data.cardNumber + Config.urlqrcode2} style={{ width: 80, height: 80 }} /></a>
                                  </td>
                                  <td>{data.firstName} {data.lastName}</td>
                                      <td>{data.phone}</td>
                                      <td>{data.cardNumber}</td>
                                  <td>{new Date(data.expireDate).toLocaleDateString()}</td>
                                  <td>
                                    <a href={"/0f29ddd4be2fe3e504e308f0e9827a39/admin/students/edit/" + data.id} className="btn btn-xs btn-info btn-rounded">Edit</a>
                                    <a href={'/imprimir' + '?' + 'CAD=' + data.cardNumber + Config.urlqrcode2} target="_blank" className="btn btn-xs btn-success mx-2 btn-rounded">Imprimir</a>
                                    {/* <button type="button" onClick={() => { ConfirmDelete(data._id) }} className="btn btn-xs btn-danger btn-rounded">Delete</button> */}
                                  </td>
                                </tr>

                              );

                            })
                          }
                        </tbody>
                      </Table>
                      {paginationData && <PaginationComponent paginate={paginate} searchValue={searchValue} paginationData={paginationData} />}

                    </div>
                  </div>
                </>
                :
                <div className="row">
                  <div className="col-lg-12">
                    <Skeleton count={5} />
                  </div>
                </div>

              }

            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
}