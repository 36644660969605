import React from "react";
import axios from "axios";
import Config from "../../Config";
import domtoimage from "dom-to-image";
import { useHistory, useParams,  useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import VO from "../../assets/img/VO.png";
import Correcto from "../../assets/img/CORRECT.png";
import validator from 'validator';
import './registro.css';
function ImprimirScreen() {
  //Hooks
  const [data, setData] = React.useState(null);
  
 
  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  React.useEffect(() => {
    getRegistro();
  }, []);

  async function getRegistro() {

    let url;
    let id = query.get('CAD')
    if(id){
      url = Config.url + "estudiantes/CAD/" + id
    }else{
      return;
    }
    

    await axios
      .get(url)
      .then((response) => {
        setData(response.data);
        //consultar curso
        document.title = "Outreach Portal E-Verify"
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const Imprimir = async (data) => {
    
    const input = document.getElementById("printarea");
    
    //html2pdf(input);
    //const pdf = new jsPDF();
    const doc = new jsPDF("p" , "px" , [637, 1009]);
    if (doc) {
    await  domtoimage
        .toPng(input)
        .then((imgData) => {
          let img = new Image();
          img.src = imgData;
          console.log(imgData);
          // pdf.addImage(img, 'JPG', 0, 0);
          // pdf.save('download.pdf');

          const bufferX = 15;
          const bufferY = 15;
          const imgProps = doc.getImageProperties(img);
          //const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
          let pageHeight = doc.internal.pageSize.getHeight();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          let heightLeft = pdfHeight;

          let position = 0;
          doc.addImage(img, "PNG", 15, 15, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;
          

          

          

          // while (heightLeft >= 0) {
          //   position += heightLeft - pdfHeight; // top padding for other pages
          //   doc.addPage();
          //   doc.addImage(
          //     imgData,
          //     "PNG",
          //     15,
          //     position,
          //     pdfWidth,
          //     pdfHeight + 25
          //   );
          //   heightLeft -= pageHeight;
          // }
          // doc.save('download.pdf');
          return doc;
        })
        .then((docResult) => {
            docResult.save(
              `${new Date().toLocaleDateString()}_preview.pdf`
            );
          });

   
       
        
    }

    // const DATA = document.getElementById('printarea');
    // const doc = new jsPDF('p', 'pt', 'a4');
    // const options = {
    //   background: 'white',
    //   scale: 3
    // };
    // html2canvas(DATA, options).then((canvas) => {

    //   const img = canvas.toDataURL('image/PNG');

    //   // Add image Canvas to PDF
    //   const bufferX = 15;
    //   const bufferY = 15;
    //   const imgProps = (doc).getImageProperties(img);
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    //   return doc;
    // }).then((docResult) => {
    //   docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
    // });
  };



  return (
    <>
    <button type="button" onClick={()=>Imprimir()}>Imprimir</button>
      {data && (
        <div  style={{ width: "637px", height: '1009px',  margin: "auto" , border: '1px solid black'}}>
            <div className="container verify-outreach printarea" id="printarea">
        <div className="row">
          <div className="col text-center">
            <img src={Correcto} className=" w-50 img-responsive img-fluid" />
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <h4><strong>VALID STUDENT CARD</strong></h4>
            <p>
              <b>NAME:</b> <br/>
              {data && data.firstName} {data && data.lastName} <br/>
              <b>CARD NUMBER:</b> <br/>
              <span>{data && data.cardNumber}</span>
              {
                data && data.certificado.map((data,index)=>{
              return(
                <div key={index}>
                <b>{data.horasEntrenamiento} HR. <br />
                {data.curso.titulo}</b>
                </div>
              )
            })}
            </p>
            <p>
            <img src={VO} className="img-responsive img-fluid" />
            </p>
          </div>
        </div>
    </div>
        </div>
      )}
    </>
  );
}

export default ImprimirScreen;
