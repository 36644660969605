import React from "react";
import axios from "axios";
import Config from "../../Config";
import { useHistory,   useLocation } from "react-router-dom";
import Correcto from "../../assets/img/CORRECT.png";
import VO from "../../assets/img/VO.png";
import validator from 'validator';
import '../../App.css';
import './registro.css';
export default function VerRegistro(props) {
  const history = useHistory();
  const location = history;

  const [data, setData] = React.useState(null);
  const [dataCertificates, setDataCertificates] = React.useState([]);

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  
  React.useEffect(() => {
    document.title = "Outreach Portal E-Verify";
    getRegistro();

    console.log(query.get('CAD'))
  }, []);

  

  async function getRegistro() {

    let url;
    let id = query.get('CAD')
    if(id){
      url = Config.url + "estudiantes/CAD/" + id
    }else{
      return;
    }
    

    await axios
      .get(url)
      .then((response) => {
        setData(response.data);
        //consultar curso
        document.title = "Outreach Portal E-Verify"
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  console.log(props);
  return (
    
    <div className="container verify-outreach">
        <div className="row">
          <div className="col text-center">
            <img src={Correcto} className=" w-50 img-responsive img-fluid" />
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <h4><strong>VALID STUDENT CARD</strong></h4>
            <p>
              <b>NAME:</b> <br/>
              {data && data.firstName} {data && data.lastName} <br/>
              <b>CARD NUMBER:</b> <br/>
              <span>{data && data.cardNumber}</span>
              {
                data && data.certificado.map((data,index)=>{
              return(
                <div key={index}>
                <b>{data.horasEntrenamiento} HR. <br />
                {data.curso.titulo}</b>
                </div>
              )
            })}
            </p>
            <p>
            <img src={VO} className="img-responsive img-fluid" />
            </p>
          </div>
        </div>
    </div>
 
   
  );
}
